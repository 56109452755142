import { Injectable } from '@angular/core';
import Big from 'big.js';

@Injectable({
  providedIn: 'root',
})
export class RoundNumberService {
  roundNumber(number: number | Big, decimals: number = 2): number {
    return Number(Big(number).round(decimals, Big.roundHalfUp).toString());
  }

  roundNumberDown(number: number | Big, decimals: number = 2): number {
    return Number(Big(number).round(decimals, Big.roundDown).toString());
  }

  roundLoyaltyAmount(value: Big | number, decimals: number = 2): number {
    const bigValue = Big(value);

    if (decimals === 0) {
      const intPart = bigValue.round(0, Big.roundDown);
      const decimalPart = bigValue.minus(intPart);
      if (decimalPart.gte(0.1)) {
        return intPart.plus(1).toNumber();
      }
      return intPart.toNumber();
    }

    const multiplier = Big(10).pow(decimals);
    const adjustedNumber = bigValue.times(multiplier);
    const roundedNumber = Big(Math.round(adjustedNumber.toNumber()));
    return roundedNumber.div(multiplier).toNumber();
  }
}
